import { startCase } from 'lodash'
// Types.
//
import type { BookingProductReservation, Money, TagScope } from '~/apollo/types'
import type { Alert } from '~/stores/alerts'

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]
export const mixin = {
  rkLastWord(text: string, pattern?: string): any {
    // Roll-back to backslash.
    //
    const regex = pattern ? pattern : /[^\\]+$/
    return text.match(regex)![0] || null
  },
  rkIdGenerate(customId?: number) {
    return customId || Date.now() + Math.floor(Math.random() * 100)
  },
  rkClassToggle(target: any, className: string) {
    if (!target.classList.contains(className)) {
      target.classList.add(className)
    } else {
      target.classList.remove(className)
    }
  },
  rkIndexClassToggle(target: any, className: string, id: number) {
    Array.prototype.forEach.call(target, function (item, index) {
      if (index === id && !item.classList.contains(className)) {
        item.classList.add(className)
      } else {
        item.classList.remove(className)
      }
    })
  },
  rkCurrencySymbol(code: string) {
    const currencySymbols: any = {
      USD: '$',
      EUR: '€',
      GBP: '£',
      CAD: '$',
      NZD: '$',
      AUD: '$',
      INR: '₹',
    }
    return currencySymbols[code]
  },
  rkPriceFormat(value: number | any, code: string, digits: number) {
    const penceConversion = value / 100
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: code,
      minimumFractionDigits: digits,
    })
    return formatter.format(penceConversion)
  },
  rkSum(r: BookingProductReservation[]): Money {
    if (r.length === 0) {
      return {
        amount: 0,
        currency: 'GBP',
      }
    }

    const amount = r
      .map((r) => r.price?.amount || r.product.price?.amount || 0)
      .reduce((a, b) => a + b)

    return {
      amount,
      currency: r[0].price?.currency || r[0].product.price?.currency || 'GBP',
    }
  },
  rkSetTag(tag: string, scope: TagScope) {
    const idx = useTagStore().tags.findIndex(
      (t: any) => t.scope === scope && t.tag === tag,
    )
    const item = useTagStore().tags[idx]
    return item ? { name: item.tag, val: item.tag } : null
  },
  rkSetBookingStatus(key: string) {
    const status: any = {
      PENDING: { name: 'Pending', val: 'PENDING' },
      ON_HOLD: { name: 'On Hold', val: 'ON_HOLD' },
      CANCELLED: { name: 'Cancelled', val: 'CANCELLED' },
      CONFIRMED: { name: 'Confirmed', val: 'CONFIRMED' },
      ERROR: { name: 'Error', val: 'ERROR' },
      ARCHIVED: { name: 'Archived', val: 'ARCHIVED' },
    }
    return status[key]
  },
  rkRevertCamelCase(str: string) {
    return startCase(str)
  },
  rkSetCurrentTagsFromArray(array: any[], scope: TagScope) {
    array.map((item: any) => useTagStore().setTags(item.id, item.tags, scope))
  },
  rkSetDateOfBirth(date: any) {
    if (!date) return null

    const d = new Date(date)
    d.setUTCHours(0, 0, 0, 0)

    const userTimezoneOffset = d.getTimezoneOffset() * 60000

    const final = new Date(
      d.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset),
    )

    final.setUTCHours(0, 0, 0, 0)
    return final
  },
  rkGetDateOfBirth(date: any) {
    if (!date) return null

    const d = new Date(date)
    if (d.getTimezoneOffset() < 0) {
      d.setHours(0, 0, 0, 0)
    }

    const userTimezoneOffset = d.getTimezoneOffset() * 60000

    const final = new Date(
      d.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset),
    )

    if (d.getTimezoneOffset() > 0) {
      final.setUTCHours(0, 0, 0, 0)
      return final
    }

    return final
  },
}

export const objStringify = (data: any) => {
  return JSON.stringify(data)
}
